import classes from './StrengthTraining.module.css';
import React, {useReducer, useCallback} from 'react';
import {StrengthTrainingGender} from './partials/StrengthTrainingGender';
import {StrengthTrainingMaxReps} from './partials/StrengthTrainingMaxReps';
import {StrengthTrainingObjective} from './partials/StrengthTrainingObjective';
import {StrengthTrainingExercise} from './partials/StrengthTrainingExercise';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {STATISTICS_EVENTS} from '../../utils/statistics';
import {logEvent} from '../../utils/logEvent';

export interface StrengthCalculatorStateType {
  gender: 'male' | 'female' | 'other',
  reps: {
    squats: '' | number,
    pushUps: '' | number,
    sitUp: '' | number,
    ubHypEx: '' | number
  },
  objective: 'Hypertrophy' | 'Explosive strength' | 'Muscle endurance' | 'Maximal strengths' | '';
  exercises: string[];
}

export type RepsType = keyof StrengthCalculatorStateType['reps'];

const initialState = {
  gender: 'male',
  reps: {
    squats: 0,
    pushUps: 0,
    sitUp: 0,
    ubHypEx: 0
  },
  objective: 'Hypertrophy',
  exercises: []
} as StrengthCalculatorStateType;

function reducer(state: StrengthCalculatorStateType, action: { type: string, payload: any }) {
  switch (action.type) {
    case 'changeGender':
      return {
        ...state,
        gender: action.payload
      };
    case 'changeReps':
      return {
        ...state,
        reps: {
          ...state.reps,
          [action.payload.key]: action.payload.value
        }
      }
    case 'changeObjective':
      return {
        ...state,
        objective: action.payload
      }
    case 'addExercises':
      const newExercises = [...state.exercises, ...action.payload];
      return {
        ...state,
        exercises: newExercises
          .filter((exercise, i) => newExercises.indexOf(exercise) === i)
      }
    case 'removeExercise':
      return {
        ...state,
        exercises: state.exercises.filter(exercise => exercise !== action.payload)
      }
    default:
      throw new Error();
  }
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const sheetsScriptUrl = 'https://script.google.com/macros/s/AKfycbwBpC-Wd0OVs3f0VwYiR8tUhl5az0V00ZfaszN0ZEQc-NNRFXnC7w9mCRVlpCCOeiEZ/exec'

export function StrengthTraining() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = useCallback(() => {
    logEvent(STATISTICS_EVENTS.CALCULATE_RESULTS);
    handleOpen();

    const formData = new FormData();
    formData.append('gender', state.gender);
    formData.append('squats', state.reps.squats.toString());
    formData.append('pushups', state.reps.pushUps.toString());
    formData.append('situp', state.reps.sitUp.toString());
    formData.append('ubhypex', state.reps.ubHypEx.toString());
    formData.append('objective', state.objective);
    formData.append('exercise', state.exercises.join(', '));


    fetch(sheetsScriptUrl, {method: 'POST', body: formData}).then(() => {
      console.log('get results')
    });
  }, [state]);

  return <div className={classes.StrengthTraining}>
    <header className={classes.StrengthTrainingHeader}>
      <img src="./images/logo.svg" alt="AbeFir strength training"/>
    </header>
    <div className={classes.StrengthTrainingContent}>
      <StrengthTrainingGender
        state={state}
        dispatch={dispatch}
      />
      <StrengthTrainingMaxReps
        state={state}
        dispatch={dispatch}
      />
      <StrengthTrainingObjective
        state={state}
        dispatch={dispatch}
      />
      <StrengthTrainingExercise
        state={state}
        dispatch={dispatch}
      />
      <div>
        <button
          className={classes.StrengthTrainingGetResults}
          onClick={handleSubmit}
        >
          Get the results
        </button>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <Typography sx={{ mt: 2 }}>
              Sorry, we have a lot of requests. Try again in 15 minutes.
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  </div>
}
