import {RepsType, StrengthCalculatorStateType} from '../StrenthTraining';
import React, {useCallback} from 'react';
import classes from '../StrengthTraining.module.css';
import {TextField} from '@mui/material';

const reps = [{
  label: 'Squats',
  key: 'squats'
}, {
  label: 'Push ups',
  key: 'pushUps'
}, {
  label: 'Sit Up',
  key: 'sitUp'
}, {
  label: 'UB hyp-ex.',
  key: 'ubHypEx'
}] as {
  label: string,
  key: RepsType
}[]

export function StrengthTrainingMaxReps(
  {state, dispatch}: {
    state: StrengthCalculatorStateType,
    dispatch: React.Dispatch<{type: string, payload: any}>
  }
) {
  const handleChangeReps = useCallback(
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'changeReps',
        payload: {
          key: key,
          value: event.target.value.trim() === '' ? '' : Number(event.target.value)
        }
      })
    },
    [dispatch]
  )

  return <>
    <div className={classes.StrengthTrainingDescription}>
      Enter the maximum number of reps the athlete
      can perform (without overextending or injuring themselves) for each exercise
    </div>
    <div className={classes.maxReps}>
      <div className={classes.maxRepsBlockLabel}>
        Max reps
      </div>
      {
        reps.map(rep => (
          <div key={rep.key}>
            <div className={classes.maxRepsItemLabel}>{rep.label}</div>
            <div>
              <TextField
                variant="outlined"
                type={'number'}
                size={'small'}
                value={state.reps[rep.key] as ('' | number)}
                onChange={handleChangeReps(rep.key)}
                InputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
                sx={{
                  width: 60
                }}
              />
            </div>
          </div>
        ))
      }
    </div>
  </>
}
