import classes from '../StrengthTraining.module.css';
import {AbeButtonsGroup} from '../../AbeButtonsGroup/AbeButtonsGroup';
import Button from '@mui/material/Button';
import {StrengthCalculatorStateType} from '../StrenthTraining';
import React from 'react';

export function StrengthTrainingGender(
  {state, dispatch}: {
    state: StrengthCalculatorStateType,
    dispatch: React.Dispatch<{type: string, payload: any}>
  }
) {
  return (
    <div className={classes.StrengthTrainingGender}>
      <div>
        <div className={classes.StrengthTrainingLabel}>Gender</div>
        <AbeButtonsGroup>
          {
            ({buttonStyles, activeButtonStyles}) => (
              <>
                <Button
                  onClick={() => dispatch({type: 'changeGender', payload: 'male'})}
                  variant={state.gender === 'male' ? 'outlined' : 'text'}
                  sx={
                    state.gender === 'male'
                      ? activeButtonStyles
                      : buttonStyles
                  }
                >
                  Male
                </Button>
                <Button
                  onClick={() => dispatch({type: 'changeGender', payload: 'female'})}
                  variant={state.gender === 'female' ? 'outlined' : 'text'}
                  sx={
                    state.gender === 'female'
                      ? activeButtonStyles
                      : buttonStyles
                  }
                >
                  Female
                </Button>
                <Button
                  onClick={() => dispatch({type: 'changeGender', payload: 'other'})}
                  variant={state.gender === 'other' ? 'outlined' : 'text'}
                  sx={
                    state.gender === 'other'
                      ? activeButtonStyles
                      : buttonStyles
                  }
                >
                  Other
                </Button>
              </>
            )
          }
        </AbeButtonsGroup>
      </div>
    </div>
  )
}
