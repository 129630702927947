import {StrengthCalculatorStateType} from '../StrenthTraining';
import React from 'react';
import classes from '../StrengthTraining.module.css';
import {Select, SelectChangeEvent} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export function StrengthTrainingObjective(
  {state, dispatch}: {
    state: StrengthCalculatorStateType,
    dispatch: React.Dispatch<{ type: string, payload: any }>
  }
) {
  const handleChangeObjective = (event: SelectChangeEvent) => {
    dispatch({
      type: 'changeObjective',
      payload: event.target.value
    })
  };

  return <>
    <div className={classes.StrengthTrainingDescription}>
      Choose objectives for the training session
    </div>
    <div className={classes.StrengthTrainingObjective}>
      <div className={classes.StrengthTrainingLabel}>Objective</div>
      <Select
        value={state.objective}
        onChange={handleChangeObjective}
        sx={{
          width: '100%',
          maxWidth: '320px',
          height: '40px'
        }}
      >
        <MenuItem value={'Hypertrophy'}>Hypertrophy</MenuItem>
        <MenuItem value={'Explosive strength'}>Explosive strength</MenuItem>
        <MenuItem value={'Muscle endurance'}>Muscle endurance</MenuItem>
        <MenuItem value={'Maximal strengths'}>Maximal strengths</MenuItem>
      </Select>
    </div>
  </>
}
