import {ReactNode} from "react";

export interface AbeButtonsGroupProps {
  children: ({
    buttonStyles,
    activeButtonStyles
  }: {
  buttonStyles: Record<string, number | string>,
  activeButtonStyles: Record<string, number | string>
}) => ReactNode
}


const buttonStyles = {
  color: 'var(--mid-gray)',
  border: '1px solid transparent',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  padding: '11px 32px',
  textTransform: 'none',
};

const activeButtonStyles = {
  ...buttonStyles,
  color: 'var(--black-dark)',
  border: '1px solid var(--blue-dark)',
}

export function AbeButtonsGroup({children}: AbeButtonsGroupProps) {
  return <div>
    {children({buttonStyles, activeButtonStyles})}
  </div>
}
