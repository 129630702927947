import React from 'react';
import {StrengthTraining} from './components/StrengthTraining/StrenthTraining';

function App() {
  return (
    <div>
      <StrengthTraining/>
    </div>
  );
}

export default App;
