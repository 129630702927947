import {StrengthCalculatorStateType} from '../StrenthTraining';
import React, {KeyboardEvent, useState} from 'react';
import classes from '../StrengthTraining.module.css';
import {TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export function StrengthTrainingExercise(
  {state, dispatch}: {
    state: StrengthCalculatorStateType,
    dispatch: React.Dispatch<{type: string, payload: any}>
  }
) {
  const [exerciseStr, setExerciseStr] = useState('');

  return <>
    <div className={classes.StrengthTrainingDescription}>
      Type the name of exercise
    </div>
    <div className={classes.StrengthTrainingExerciseBlock}>
      <div className={classes.StrengthTrainingLabel}>Exercise</div>
      <TextField
        placeholder={'Add exercise'}
        variant="outlined"
        size={'small'}
        value={exerciseStr}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setExerciseStr(event.currentTarget.value.trim())}
        sx={{
          width: '100%',
          maxWidth: '320px'
        }}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            if (exerciseStr !== '') {
              dispatch({
                type: 'addExercises',
                payload: [exerciseStr]
              })
            }
            setExerciseStr('')
          }
        }}
        onBlur={() => {
          if (exerciseStr !== '') {
            dispatch({
              type: 'addExercises',
              payload: [exerciseStr]
            })
          }
          setExerciseStr('')
        }}
      />
    </div>
    <div className={classes.StrengthTrainingExerciseTags}>
      {
        state.exercises.map(exercise => (
          <div key={exercise} className={classes.StrengthTrainingExerciseTag}>
            {exercise}
            <CloseIcon
              fontSize={'small'}
              sx={{
                marginLeft: '10px',
                cursor: 'pointer'
              }}
              onClick={() => dispatch({
                type: 'removeExercise',
                payload: exercise
              })}
            />
          </div>
        ))
      }
    </div>
  </>
}
